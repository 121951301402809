import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { Link } from "gatsby";
import travolta from "../images/travolta.gif";

const NotFoundPage = (): JSX.Element => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="notfound__block">
      <h2>Whaa?</h2>
      <p>It seems that you got lost...</p>
      {/* <p>It seems like the page that you are lookin for is no longer here.</p> */}
      <Link
        to="/"
        className="btn white outline"
        style={{ marginTop: "4.8rem" }}
      >
        &laquo; Take me home
      </Link>
    </div>
    <img src={travolta} alt="" className="notfound__img" />
  </Layout>
);

export default NotFoundPage;
